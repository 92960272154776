<template>
  <div id="errorPage">
    <div class="container">
      <font-awesome-icon :icon="fontAwesomeIcon" size="6x" />
      <p />
      <h1>{{ errorMessage }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fontAwesomeIcon: Array,
    errorMessage: String
  },
  name: "CommonError"
};
</script>
<style>
#errorPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#errorPage .container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
